export default {
  colors: {
    iris: {
      '60': '#A5A6F6',
      '80': '#7879F1',
      '100': '#5D5FEF',
    },
    purple: {
      '50': '#EDEAFB',
      '100': '#CCC3F3',
      '200': '#AC9DEC',
      '300': '#8B77E4',
      '400': '#6B50DD',
      '500': '#351E98',
      '600': '#3B22AA',
      '700': '#2D1980',
      '800': '#1E1155',
      '900': '#0F082B',
    },
    blue: {
      '50': '#EAE5FF',
      '100': '#C5B8FF',
      '200': '#A08AFF',
      '300': '#7B5CFF',
      '400': '#552EFF',
      '500': '#461BFF',
      '600': '#2600CC',
      '700': '#1D0099',
      '800': '#130066',
      '900': '#0A0033',
    },
    'blue.dark': {
      '50': '#E9E9FC',
      '100': '#C2C1F6',
      '200': '#9B99F0',
      '300': '#7471EA',
      '400': '#4D49E4',
      '500': '#2521DF',
      '600': '#1E1AB2',
      '700': '#161386',
      '800': '#0F0D59',
      '900': '#07062D',
    },
    cyan: {
      '50': '#E5F7FF',
      '100': '#B8E8FF',
      '200': '#8AD9FF',
      '300': '#5CCBFF',
      '400': '#2EBCFF',
      '500': '#00ADFF',
      '600': '#008ACC',
      '700': '#006899',
      '800': '#004566',
      '900': '#002333',
    },
    teal: {
      '50': '#E5FFF7',
      '100': '#B8FFE9',
      '200': '#8AFFDB',
      '300': '#5CFFCD',
      '400': '#2EFFBE',
      '500': '#00D996',
      '600': '#00CC8D',
      '700': '#00996A',
      '800': '#006647',
      '900': '#003323',
    },
    green: {
      '50': '#EBFAF5',
      '100': '#C6F1E4',
      '200': '#A1E8D2',
      '300': '#7CDFC1',
      '400': '#57D5AF',
      '500': '#1A6951',
      '600': '#28A47E',
      '700': '#1E7B5F',
      '800': '#14523F',
      '900': '#0A2920',
    },
    yellow: {
      '50': '#FFF9E5',
      '100': '#FFEDB8',
      '200': '#FFE18A',
      '300': '#FFD55C',
      '400': '#FFCA2E',
      '500': '#FFBE00',
      '600': '#CC9800',
      '700': '#997200',
      '800': '#664C00',
      '900': '#332600',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    red: {
      '50': '#FFF5F5',
      '100': '#FED7D7',
      '200': '#FEB2B2',
      '300': '#FC8181',
      '400': '#F56565',
      '500': '#E53E3E',
      '600': '#C53030',
      '700': '#9B2C2C',
      '800': '#822727',
      '900': '#63171B',
    },
    orange: {
      50: '#FFFAF0',
      100: '#FEEBC8',
      200: '#FBD38D',
      300: '#F6AD55',
      400: '#ED8936',
      500: '#DD6B20',
      600: '#C05621',
      700: '#9C4221',
      800: '#7B341E',
      900: '#652B19',
    },
  },
};

